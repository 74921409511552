<template>
  <v-main class="pa-0">
    <v-container>
      <v-row>
        <v-col cols='12'>
          <v-card-title class="pb-0">
            6. Политика пао «Газпром» в области охраны труда, промышленной и пожарной безопасности, безопасности дорожного движения
          </v-card-title>
        </v-col>
        <v-col cols='12'>
          <v-card-title class="title font-weight-light" style="word-break: break-word;">
            Деятельность ПАО «Газпром» и его дочерних обществ имеет стратегическое значение для экономики России и других стран. ПАО «Газпром», являясь крупнейшей газовой компанией мира и одной из крупнейших энергетических компаний, занимающихся геологоразведкой, добычей, транспортировкой, хранением, переработкой, реализацией газа и других углеводородов, а также производством электроэнергии, в полной мере осознает свою ответственность перед обществом за создание безопасных условий труда, включая безопасность дорожного движения при осуществлении своей производственной деятельности, обеспечение требований промышленной и пожарной безопасности.
          </v-card-title>
          <v-card-title class="title font-weight-light" style="word-break: break-word;">
          Руководство ПАО «Газпром» и его дочерних обществ признает приоритетным обеспечение жизни и здоровья работников, рассматривает охрану труда, промышленную и пожарную безопасность, безопасность дорожного движения как необходимые элементы эффективного управления производственной деятельностью.
          </v-card-title>
        </v-col>
        <v-col
          cols="6"
        >
          <v-dialog
            v-model="dialog1"
            width="60%"
            class="pa-10"
          >
            <template v-slot:activator="{ on, attrs }">

            <v-card
            v-bind="attrs"
            v-on="on"
            class="pa-2 d-flex justify-center align-center"
            >
              <v-img
                lazy-src="img/material/target.svg"
                max-width="80"
                src="img/material/target.svg"
                class="ma-1"
              ></v-img>
              <v-spacer></v-spacer>
              <v-card-text class="title font-weight-light">
                Цели в области охраны труда, промышленной и пожарной безопасности, безопасности дорожного движения
              </v-card-text>
            </v-card>
            </template>
            <v-card class="noselect">
              <v-card-title class="headline" dark>
                Цели в области охраны труда, промышленной и пожарной безопасности, безопасности дорожного движения
              </v-card-title>
              <v-card-text class="title font-weight-medium">
                Основными целями ПАО «Газпром» и его дочерних обществ в области охраны труда, промышленной и пожарной безопасности, безопасности дорожного движения являются:
              </v-card-text>
              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                создание безопасных условий труда, сохранение жизни и здоровья работников;
              </v-card-text>
              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                снижение рисков аварий и инцидентов на опасных производственных объектах;
              </v-card-text>
              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                снижение рисков дорожно-транспортных происшествий, связанных с производственной деятельностью;
              </v-card-text>
              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                обеспечение пожарной безопасности.
              </v-card-text>
              <v-card-text class="title font-weight-medium mt-5">
                Цели достигаются путем предупреждения несчастных случаев, профессиональных заболеваний, аварий, инцидентов, пожаров, дорожно-транспортных происшествий на основе:
              </v-card-text>
              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                идентификации опасностей;
              </v-card-text>
              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                оценки и управления рисками в области производственной безопасности;
              </v-card-text>
              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                повышения компетентности работников и их представителей, вовлечения их в систему управления производственной безопасностью.
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  text
                  @click="dialog1 = false"
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>

        <v-col
          cols="6"
        >
          <v-dialog
            v-model="dialog2"
            width="90%"
            class="pa-10"
          >
            <template v-slot:activator="{ on, attrs }">

            <v-card
            v-bind="attrs"
            v-on="on"
            class="pa-2 d-flex justify-center align-center"
            >
              <v-img
                lazy-src="img/material/guarantee.svg"
                max-width="80"
                src="img/material/guarantee.svg"
                class="ma-1"
              ></v-img>
              <v-spacer></v-spacer>
              <v-card-text class="title font-weight-light">
                Обязательства в области охраны труда, промышленной и пожарной безопасности, безопасности дорожного движения
              </v-card-text>
            </v-card>
            </template>
            <v-card class="noselect">
              <v-card-title class="headline" dark>
                Обязательства в области охраны труда, промышленной и пожарной безопасности, безопасности дорожного движения
              </v-card-title>
              <v-card-text class="title font-weight-medium">
                Для достижения заявленных целей ПАО «Газпром» и его дочерние общества принимают на себя следующие обязательства:
              </v-card-text>
              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                постоянно снижать показатели производственного травматизма, профессиональных заболеваний, аварийности, а также минимизировать риски возникновения пожаров, дорожно-транспортных происшествий, связанных с производственной деятельностью;
              </v-card-text>
              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                обеспечивать соблюдение требований нормативных правовых актов, нормативных документов федерального, регионального и корпоративного уровней в области производственной безопасности;
              </v-card-text>
              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                обеспечивать эффективное функционирование и непрерывное совершенствование системы управления производственной безопасностью, в том числе развивая культуру производственной безопасности;
              </v-card-text>
              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                осуществлять оценку рисков в области производственной безопасности, обеспечивать управление рисками для предупреждения возникновения травм, ухудшения здоровья работников, повреждения оборудования и имущества;
              </v-card-text>
              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                обеспечивать последовательное и непрерывное выполнение мероприятий, направленных на устранение опасностей и снижение рисков в области производственной безопасности;
              </v-card-text>
              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                обеспечивать внедрение научных разработок, технологий и методов в области производственной безопасности;
              </v-card-text>
              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                привлекать работников и их представителей к активному участию в деятельности по обеспечению требований производственной безопасности, созданию здоровых и безопасных условий труда;
              </v-card-text>
              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                постоянно повышать компетентность работников в области производственной безопасности;
              </v-card-text>
              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                предусматривать необходимые организационные, финансовые, человеческие и материально-технические ресурсы для реализации настоящей Политики;
              </v-card-text>
              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                требовать от поставщиков и подрядчиков, осуществляющих деятельность в интересах ПАО «Газпром» и его дочерних обществ, соблюдения требований нормативных правовых актов, нормативных документов федерального, регионального и корпоративного уровней в области производственной безопасности.
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  text
                  @click="dialog2 = false"
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <Hint></Hint>
      </v-row>
    </v-container>
  </v-main>
</template>
<script>
import Hint from '@/components/Hint.vue'
export default {
  data: () => ({
    dialog1: null,
    dialog2: null
  }),
  components: {
    Hint
  }
}
</script>
<style scoped>
.ptm-arrow-block {
  position: absolute;
  right: 1%;
  bottom: -25%;
}
.ptm-progress-block {
  position: absolute;
  right: 1%;
  top: 0%;
}
</style>
